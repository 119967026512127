import React, { useState, useEffect } from 'react';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('home');

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const sections = ['home', 'services', 'about', 'footer'];
      let currentSection = 'home'; // Default to 'home'

      sections.forEach((section) => {
        const element = document.getElementById(section);
        if (element) {
          const rect = element.getBoundingClientRect();
          if (
            rect.top <= window.innerHeight / 2 &&
            rect.bottom >= window.innerHeight / 2
          ) {
            currentSection = section;
          }
        }
      });

      setActiveSection(currentSection);
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial check

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header className="bg-primaryWhite py-4 shadow-md fixed w-full top-0 left-0 z-50 font-sans">
      {/* Adjust container width and remove extra padding */}
      <div className="flex justify-between items-center max-w-full mx-auto px-4 md:px-6 lg:px-8">
        <div className="text-2xl md:text-3xl font-bold text-primaryBlack">
          Prasad Agri Export
        </div>

        {/* Navigation Menu and Inquiry Button */}
        <div className="flex items-center space-x-4 md:space-x-8">
          {/* Mobile Menu Button */}
          <button
            className="md:hidden text-primaryBlack focus:outline-none"
            onClick={toggleMenu}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d={isOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16m-7 6h7'}
              ></path>
            </svg>
          </button>

          {/* Navigation Links */}
          <nav
            className={`${
              isOpen ? 'block' : 'hidden'
            } md:flex md:items-center absolute md:static left-0 w-full md:w-auto top-14 md:top-0 bg-primaryWhite md:bg-transparent z-10`}
          >
            <a
              href="#home"
              className={`block py-2 px-4 ${
                activeSection === 'home'
                  ? 'text-primaryGreen'
                  : 'text-primaryBlack'
              } hover:text-primaryGreen`}
            >
              Home
            </a>
            <a
              href="#services"
              className={`block py-2 px-4 ${
                activeSection === 'services'
                  ? 'text-primaryGreen'
                  : 'text-primaryBlack'
              } hover:text-primaryGreen`}
            >
              Services
            </a>
            <a
              href="#about"
              className={`block py-2 px-4 ${
                activeSection === 'about'
                  ? 'text-primaryGreen'
                  : 'text-primaryBlack'
              } hover:text-primaryGreen`}
            >
              About Us
            </a>
            <a
              href="#footer"
              className={`block py-2 px-4 ${
                activeSection === 'footer'
                  ? 'text-primaryGreen'
                  : 'text-primaryBlack'
              } hover:text-primaryGreen`}
            >
              Contact
            </a>
          </nav>

          {/* Inquiry Button - Always Visible */}
          <a
            href="https://wa.me/919727676076"
            target="_blank"
            rel="noopener noreferrer"
            className="py-2 px-4 text-primaryWhite bg-primaryGreen rounded-full hover:bg-primaryBlack"
          >
            Inquiry
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
