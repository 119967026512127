import React from 'react';
import '../App.css'; // Ensure to import the CSS file

const Hero = () => {
  return (
    <section
      id="home"
      className="relative h-screen bg-primaryWhite bg-cover bg-center"
      style={{
        backgroundImage: `url(${require('../assets/images/background-image.jpg')})`,
      }}
    >
      <div className="absolute inset-0 bg-primaryBlack bg-opacity-50"></div>
      <div className="relative z-10 h-full flex flex-col justify-center items-center text-center text-primaryWhite animate-fade-in">
        <h1 className="text-4xl md:text-5xl font-bold mb-6 transform transition-transform duration-500 ease-in-out hover:scale-105">
          Goodness of Nature
        </h1>
        <p className="text-base md:text-lg mb-8 transform transition-transform duration-500 ease-in-out hover:scale-105">
          Delivering quality agricultural exports across the globe.
        </p>
        <a
          href="#services"
          className="px-6 py-3 bg-primaryGreen text-primaryWhite rounded-full hover:bg-primaryBlack transition-transform duration-300 ease-in-out transform hover:scale-110"
        >
          Explore Our Services
        </a>
      </div>
    </section>
  );
};

export default Hero;
