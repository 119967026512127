import React from 'react';
import companyOverview from '../assets/images/company-overview.jpg';
import TeamPhoto from '../assets/images/avatar.jpg';

const AboutUs = () => {
  return (
    <div className="font-sans">
      {/* Header Section */}
      <section id="about" className="bg-primaryBlack py-16 text-center">
        <h1 className="text-5xl font-extrabold text-primaryGreen mb-4">
          About Us
        </h1>
        <p className="text-primaryWhite mt-4 max-w-3xl mx-auto text-lg leading-relaxed">
          At Prasad Agri Export, we connect nature’s finest agricultural
          products to homes and businesses around the globe.
        </p>
      </section>

      {/* Company Information Section */}
      <section className="py-20 bg-gradient-to-r from-white to-gray-100">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-10 items-center">
          <div>
            <h2 className="text-4xl font-extrabold text-primaryBlack mb-6">
              Who We Are
            </h2>
            <p className="text-primaryBlack text-lg mb-6 leading-relaxed">
              Prasad Agri Export is a trusted name in agricultural export,
              delivering premium spices, seeds, and other natural products from
              farms to your doorstep. We prioritize quality and uphold the
              highest standards of sustainability.
            </p>
            <p className="text-primaryBlack text-lg mb-6 leading-relaxed">
              Our team combines decades of experience with a passion for
              promoting the rich diversity of India’s agricultural produce
              worldwide. We ensure our products meet global standards for safety
              and freshness.
            </p>
          </div>
          <div>
            <img
              src={companyOverview}
              alt="Prasad Agri Export"
              className="h-80 w-full object-cover rounded-lg border border-gray-300 shadow-lg transition-transform transform hover:scale-105 duration-300"
            />
          </div>
        </div>
      </section>

      {/* Mission, Vision & Values Section */}
      <section className="py-20 bg-gradient-to-b from-gray-100 to-gray-50">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-extrabold text-primaryBlack mb-12">
            Our Mission, Vision & Values
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            <div className="p-6 bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 transform hover:scale-105">
              <h3 className="text-3xl font-bold text-primaryGreen mb-4">
                Our Mission
              </h3>
              <p className="text-primaryBlack text-lg leading-relaxed">
                We aim to deliver premium, sustainably sourced agricultural
                products, while supporting local farmers and promoting
                responsible farming practices.
              </p>
            </div>
            <div className="p-6 bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 transform hover:scale-105">
              <h3 className="text-3xl font-bold text-primaryGreen mb-4">
                Our Vision
              </h3>
              <p className="text-primaryBlack text-lg leading-relaxed">
                To be a global leader in agricultural exports, known for our
                commitment to quality, innovation, and environmental
                stewardship.
              </p>
            </div>
            <div className="p-6 bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 transform hover:scale-105">
              <h3 className="text-3xl font-bold text-primaryGreen mb-4">
                Our Values
              </h3>
              <p className="text-primaryBlack text-lg leading-relaxed">
                Integrity, sustainability, and customer-centricity guide
                everything we do, ensuring we build trust and long-term
                relationships with our clients.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Why Choose Us Section */}
      <section className="py-20 bg-white text-center">
        <div className="container mx-auto">
          <h2 className="text-4xl font-extrabold text-primaryBlack mb-12">
            Why Choose Us?
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
            <div className="p-6 bg-gray-100 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 transform hover:scale-105">
              <h4 className="text-2xl font-semibold text-primaryGreen mb-4">
                Quality Assurance
              </h4>
              <p className="text-primaryBlack leading-relaxed">
                Our products go through stringent quality checks to ensure they
                meet international standards, giving you only the best.
              </p>
            </div>
            <div className="p-6 bg-gray-100 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 transform hover:scale-105">
              <h4 className="text-2xl font-semibold text-primaryGreen mb-4">
                Sustainable Practices
              </h4>
              <p className="text-primaryBlack leading-relaxed">
                We are committed to promoting environmentally responsible and
                sustainable agricultural practices.
              </p>
            </div>
            <div className="p-6 bg-gray-100 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 transform hover:scale-105">
              <h4 className="text-2xl font-semibold text-primaryGreen mb-4">
                Customer Focus
              </h4>
              <p className="text-primaryBlack leading-relaxed">
                We prioritize customer satisfaction by delivering fresh,
                high-quality products and ensuring seamless global delivery.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Our Team Section */}
      <section className="py-20 bg-gray-50">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-extrabold text-primaryBlack mb-12">
            Meet Our Team
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 transform hover:scale-105">
              <img
                src={TeamPhoto}
                alt="Satish"
                className="w-32 h-32 mx-auto rounded-full mb-4 object-cover border-4 border-primaryGreen"
              />
              <h3 className="text-2xl font-semibold text-primaryGreen">
                Satish
              </h3>
              <p className="text-primaryBlack">Founder & CEO</p>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 transform hover:scale-105">
              <img
                src={TeamPhoto}
                alt="Yagnik"
                className="w-32 h-32 mx-auto rounded-full mb-4 object-cover border-4 border-primaryGreen"
              />
              <h3 className="text-2xl font-semibold text-primaryGreen">
                Yagnik
              </h3>
              <p className="text-primaryBlack">Co-Founder & Marketing Head</p>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 transform hover:scale-105">
              <img
                src={TeamPhoto}
                alt="Uday"
                className="w-32 h-32 mx-auto rounded-full mb-4 object-cover border-4 border-primaryGreen"
              />
              <h3 className="text-2xl font-semibold text-primaryGreen">Uday</h3>
              <p className="text-primaryBlack">Operations Manager</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
